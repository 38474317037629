<template>
    <div v-if="visible">

        <v-dialog 
            v-model="visible" 
            transition="dialog-top-transition" 
            persistent 
            max-width="700"
        >
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ loading ? "" : dataSource.name }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text v-if="loading">
                    <v-col
                        class="text-subtitle-1 text-center"
                        cols="12"
                    >
                        {{$t("Загрузка...")}}
                    </v-col>
                    <v-col cols="12">
                        <v-progress-linear
                            color="#a94442"
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                    </v-col>
                </v-card-text>

                <v-card-text v-else class="wrapperFormModal">
                    <v-card flat>
                        <v-card-text>
                            <v-form>
                                <v-row dense>
                                    <v-col md="auto">
                                        <v-icon v-if="noPhoto" class="personNoPhotoIcon">fas fa-user-circle</v-icon>
                                        <v-img v-else
                                            contain
                                            max-height="180"
                                            max-width="180"
                                            :src="dataSource.photoSrc"
                                        >
                                        </v-img>
                                    </v-col>
                                    <v-col>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label"> {{$t("Должность")}} </label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{dataSource.position}}</div>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label"> {{$t("Отдел")}} </label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{dataSource.department}}</div>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label"> {{$t("Контакты")}} </label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData more-per-lab-wrap">
                                                    <v-contact-item
                                                        v-for="contact in dataSource.contacts"
                                                        :key="contact.id"
                                                        :type="contact.Type == 'email' ? 'email' : 'phone'"
                                                        :title="contact.Value"
                                                        :description="contact.Description"
                                                        :hide-load="true"
                                                        :is-read-only="true"
                                                    />
                                                </div>    
                                            </v-col>
                                        </v-row>
                                    </v-col> 
                                </v-row>       
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed
                        @click="save"
                    >
                        {{$t("Закрыть")}}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    data() {
        return {
            visible: false,
            loading: false,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            resolve: null,
            reject: null,

            dataPromise: null,
            dataSource: null
        };
    },
    computed: {
        noPhoto() {
            return (!this.dataSource.photoSrc || this.dataSource.photoSrc.length === 0 );
        },
    },
    methods: {
        save()
        {
            this.visible = false;
            this.resolve();
        },
        async open (options = null) {

            this.visible = true;            
            this.dataPromise = options;

            this.loading = true;
            this.dataSource = {
                name: '', 
                position: '', 
                department: '', 
                contacts: '', 
                photoSrc: '' 
            };            

            try
            {
                let response = await this.dataPromise;
                
                if(!response.data.success)
                {
                    this.visible = false;
                    this.$notify.alert(response.data.message);                    
                    return;
                }

                this.dataSource = { 
                    name: response.data.payload.Name, 
                    position: response.data.payload.Job, 
                    department: response.data.payload.Department, 
                    contacts: response.data.payload.Contacts, 
                    photoSrc: response.data.payload.Photo 
                };
            }
            catch (ex)
            {
                console.log(ex);
            }
            finally
            {
                this.loading = false;
            }

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
    },
};
</script>
