import selectMembers from "./select-members";
import selectMembersNew from "./select-members-new";
import executersGroup from "./executers-group";
import orderCounter from "./order-counter";
import pdfView from "./pdf-view";
import userReplacePassword from "./user-replace-password";
import notifyInactive from "./notify-inactive";
import selectEnterprise from "./select-enterprise"

const dialogs = {
    namespaced: true,
    state: {

    },
    actions: {

    },
    modules: {
        selectMembers,
		selectMembersNew,
        executersGroup,
        orderCounter,
        pdfView,
        userReplacePassword,
        notifyInactive,
        selectEnterprise
    }
}

export default dialogs;