import { httpAPI } from "@/api/httpAPI";

function PrepareTree(divisions, selected) {
    let result = [];
    result.push({ id: divisions[0]?.id, label: divisions[0]?.name });
    let children = Array.from(divisions[0]?.children ? divisions[0].children.filter(x => x.type === 'Department') : []);

    if (children.length) {
        let branches = children.reduce( function (items, division) {
            if (division.type === 'Department' && (selected == null || division.id != selected.id)) {
                items.push(handleBranch(division));
            }
            return items;
        }, []);
        result[0].children = branches;
    }

    return result;
}

function handleBranch(source) {
    let branch = { id: source.id, label: source.name };
    let children = Array.from(source.children ?  source.children.filter(x => x.type === 'Department') : []);
    
    if (children.length) {
        branch.children = [];        
        children.forEach(item => {
            branch.children.push(handleBranch(item));
        });
    }

    return branch;
}

const addSubDivision = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,
        resolve: null,
        reject: null,

        new: false,
        name: {
            ru: '',
            kz: ''
        },
        parentDivision: null,
        divisions: [],
        headId: '00000000-0000-0000-0000-000000000000',
        employees: [],
        deputiesids: [],
        specpowerids: [],
        index: ''
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_NEW(state, payload) {
            state.new = payload;
        },
        SET_NAME(state, payload) {
            switch(payload.locale)
            {
                case 'ru':
                    state.name.ru = payload.value;
                    break;

                case 'kz':
                    state.name.kz = payload.value;
                    break;
            }
        },
        SET_PARENT_DIVISION(state, payload) {
            state.parentDivision = payload;
        },
        SET_DIVISIONS(state, payload) {
            state.divisions = payload;
        },
        SET_EMPLOYEES(state, payload) {
            state.employees = payload;
        },
        SET_HEAD_ID(state, payload) {
            state.headId = payload ?? '00000000-0000-0000-0000-000000000000';
        },
        SET_DEPUTIES_IDS(state, payload) {
            state.deputiesids = payload ?? [];
        },
        SET_SPECPOWERES_IDS(state, payload) {
            state.specpowerids = payload ?? [];
        },
        SET_INDEX(state, payload){
            state.index = payload;
        }
    },
    actions: {
        async open({ commit }, { selected, divisions, isNew }) {
            commit('SET_DIVISIONS', PrepareTree(divisions, selected));
            commit('SET_NEW', isNew);            
            commit('SET_LOADING', true);
            commit('SET_VISIBLE', true);

            if (isNew) {
                commit('SET_PARENT_DIVISION', selected);
                commit('SET_NAME', { locale: 'ru', value: '' });
                commit('SET_NAME', { locale: 'kz', value: '' });
                commit('SET_INDEX', '');
            }
            else {
                commit('SET_PARENT_DIVISION', selected.parent_id);

                let departmentRequest = await httpAPI({
                    url: `api/ems/getdepartment?id=${selected.id}`,
                    method: 'GET'
                });
                commit('SET_NAME', { locale: 'ru', value: departmentRequest?.data?.payload?.nameru ?? '' });
                commit('SET_NAME', { locale: 'kz', value: departmentRequest?.data?.payload?.namekz ?? '' });
                commit('SET_HEAD_ID', departmentRequest?.data?.payload?.headid ?? []);
                commit('SET_EMPLOYEES', departmentRequest?.data?.payload?.employees ?? []);
                commit('SET_DEPUTIES_IDS', departmentRequest?.data?.payload?.deputiesids ?? []);
                commit('SET_SPECPOWERES_IDS', departmentRequest?.data?.payload?.specpowerids ?? []);
                commit('SET_INDEX', departmentRequest?.data?.payload?.index ?? '');
            }
            
            commit('SET_LOADING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ name: state.name, parent: state.parentDivision, head: state.headId, deputiesids: state.deputiesids, specpowerids: state.specpowerids, index: state.index });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject();
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        isNew: (s) => s.new,
        getName: (s) => s.name,
        getParentDivision: (s) => s.parentDivision,
        getDivisions: (s) => s.divisions,
        getHeadId: (s) => s.headId,
        getEmployees: (s) => s.employees,
        getDeputies: (s) => s.deputiesids,
        getSpecPowers: (s) => s.specpowerids,
        getIndex: (s) => s.index
    }
}

export default addSubDivision;