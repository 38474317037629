import axios from "axios";
import notifier from '@/services/notifier';
import i18n from '@/i18n';
import store from '../store';

const httpAPI = axios.create();

var isLogoutNotificationActive = false;

httpAPI.interceptors.response.use(
    // Do something with response data
    function (response)
    {
        const { config } = response;

        if(config?.skipErrorHandler === true)
            return response;

        if (response.data?.success === false)
        {
            notifier.alert(response?.data?.message);
            return null;
        }

        if (response.data?.success === true && response.data?.payload?.Result === "ERROR")
        {
            notifier.alert(response.data?.payload?.Message);
            return null;
        }
        if (response.data?.success === true && response.data?.payload?.result === "ERROR")
        {
            notifier.alert(response.data?.payload?.message);
            return null;
        }

        return response;
    },
    // Do something with response error
    function (error)
    {
        const { config } = error;

        if(config?.skipErrorHandler === true)
            return Promise.reject(error);
        
        errorHandler(error);
        return null;
    }
);

const errorHandler = (error) => {

    if (error && typeof error.toJSON === "function" && error.toJSON().message === 'Network Error') {
        notifier.alert(i18n.t('Сетевое_подключение_недоступно_или_ограничено'));
    }

    const statusCode = error?.response?.status;

    if (axios.isCancel(error)) 
        notifier.warning('Действие отменено');

    if (statusCode === 400) {
        if(error.response?.data?.type === "application/json") {
            const reader = new FileReader();
            reader.onload = function() {
                let failObj = JSON.parse(this.result);
                notifier.alert(failObj.message);
            };
            reader.readAsText(error?.response?.data);
        }
        else {
            notifier.alert('Некорректный запрос');
        }
    }

    if (statusCode === 401) {
        if (store.getters['auth/isAuthorized']) {
            let message = i18n.t('Необходимо_пройти_повторную_аутентификацию')

            if (error?.response?.data)
                message += `:<br/>${error.response.data}`;

            if (!isLogoutNotificationActive) {

                isLogoutNotificationActive = true;

                notifier.confirm(
                    i18n.t(message),
                    async () =>
                    {
                        await store.dispatch('auth/fetchEmergencyLogout');
                        isLogoutNotificationActive = false;
                    },
                    false,
                    {
                        labels: {
                            confirm: i18n.t('Аутентификация')
                        }
                    }
                );
            }
        }
        else {
            notifier.alert(error?.response?.data);
        }
    }

    if (statusCode === 404) {
        notifier.alert('Конечная точка не найдена');
    }

    if (statusCode === 451) {
        notifier.warning(error?.response?.data?.message);
    }

    if (statusCode === 500) {
        if (error.response?.data?.type === "text/plain") {
            const reader = new FileReader();
            reader.onload = function() {
                notifier.alert(this.result);
            };
            reader.readAsText(error?.response?.data);
        }
        else {
            notifier.alert('Ошибка обработки запроса');
        }
    }
}

export { httpAPI };