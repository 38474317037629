const notifyInactive = {
    namespaced: true,
    state: {
        visible: false,
        openedAt: null,
        resolve: null,
        notifyInProcess: false,
        notifyRepeatRecieved: false
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_OPENED_AT(state, payload) {
            state.openedAt = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_NOTIFY_IN_PROCESS(state, payload) {
            state.notifyInProcess = payload;
        },
        SET_NOTIFY_REPEAT_RECIEVED(state, payload) {
            state.notifyRepeatRecieved = payload;
        }
    },
    actions: {
        async open({ commit }, { openedTime }) {
            commit('SET_OPENED_AT', openedTime);
            commit('SET_NOTIFY_REPEAT_RECIEVED', false);

            commit('SET_VISIBLE', true);

            return new Promise((resolve) => {
                commit('SET_RESOLVE', resolve);
            });
        },
        ok({ commit, state }) {
            state.resolve({ confirmed: true });
            commit('SET_VISIBLE', false);
        },
        cancel({ commit, state }) {
            state.resolve({ confirmed: false });
            commit('SET_VISIBLE', false);
        }
    },
    getters: {
        isVisible: (s) => s.visible,
        isNotifyInProcess: (s) => s.notifyInProcess,
        isNotifyRepeatRecieved: (s) => s.notifyRepeatRecieved
    }
}

export default notifyInactive;