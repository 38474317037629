import { httpAPI } from "@/api/httpAPI";
import i18n from '@/i18n';

const userReplacePassword = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,

        password_old: '',
        password_new: null,
        password_repeat_new: null,
        helptextarray:[],
        showmessage: false,
        askoldpass: true,

        resolve: null,
        reject: null,
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_PASSWORD_OLD(state, payload) {
            state.password_old = payload;
        },
        SET_PASSWORD_NEW(state, payload) {
            state.password_new = payload;
        },
        SET_PASSWORD_REPEAT_NEW(state, payload) {
            state.password_repeat_new = payload;
        },
        SET_HELPTEXTARRAY(state, payload){
            state.helptextarray = payload;
        },
        PUSH_HELPTEXTARRAY(state, payload){
            state.helptextarray.push(payload);
        },
        SET_SHOWMESSAGE(state, payload){
            state.showmessage = payload;
        },
        SET_ASKOLDPASS(state, payload){
            state.askoldpass = payload;
        }
    },
    actions: {
        async open({ commit },{showmessage, oldpass}) {
            commit('SET_LOADING', true);
            if (oldpass){
                commit('SET_PASSWORD_OLD', oldpass);
                commit('SET_ASKOLDPASS', false);
            }
            else {
                commit('SET_PASSWORD_OLD', '');
                commit('SET_ASKOLDPASS', true);
            }
            commit('SET_PASSWORD_NEW', '');
            commit('SET_PASSWORD_REPEAT_NEW', '');
            commit("SET_HELPTEXTARRAY",[]);
            commit('SET_SHOWMESSAGE', showmessage);
            let url = `/api/security/getsecuritysettingslist?section=Password`;  
            let response = await httpAPI({
                url,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if (response){ 
                let configrows = response.data.payload;
                configrows.forEach((item) => {
                    if(item.value > 0){
                        switch (item.code){
                            case "MIN_LEN":
                                commit("PUSH_HELPTEXTARRAY", { text: i18n.t('Минимальная_длина_пароля_value_символов.message', { value: item.value }) });
                                break;

                            case "MAX_REPEAT":
                                commit("PUSH_HELPTEXTARRAY", { text: i18n.t('Максимальное_количество_повторяющихся_подряд_символов_value.message', { value: item.value }) });
                                break;

                            case "UCR_PASS":
                                commit("PUSH_HELPTEXTARRAY", { text: i18n.t('Минимальное_количество_заглавных_латинских_букв_value.message', { value: item.value }) });
                                break;

                             case "LCR_PASS":
                                commit("PUSH_HELPTEXTARRAY", { text: i18n.t('Минимальное_количество_строчных_латинских_букв_value.message', { value: item.value }) });
                                break;

                            case "DCR_PASS":
                                commit("PUSH_HELPTEXTARRAY", { text: i18n.t('Минимальное_количество_цифр_value.message', { value: item.value }) });
                                break;

                            case "SPC_PASS":
                                commit("PUSH_HELPTEXTARRAY", { text: i18n.t('Минимальное_количество_спецсимволов_value.message', { value: item.value }) });
                                break;

                            case "MIN_DIFF_PASS":
                                commit("PUSH_HELPTEXTARRAY", { text: i18n.t('Количество_символов_в_новом_пароле_которое_не_должно_присутствовать_в_старом_пароле_value.message', { value: item.value }) });
                                break;

                            case "PREV_USED_PASS":
                                commit("PUSH_HELPTEXTARRAY", { text: i18n.t('Количество_ранее_использовавшихся_паролей_с_которыми_не_должен_совпадать_новый_value.message', { value: item.value }) });
                                break;
                        }
                    }
                });
            }
            commit('SET_LOADING', false);
            commit('SET_VISIBLE', true);
            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_LOADING', true);
            try
            {
                let response = await httpAPI({
                    url: '/api/security/changepassword',
                    method: 'POST',        
                    data: JSON.stringify({ password_old: state.password_old, password_new: state.password_new }),
                    headers: { "Content-type": "application/json; charset=UTF-8" }
                });

                if (response)
                {
                    commit('SET_VISIBLE', false);
                    state.resolve(response.data.payload.Message);
                }
            }
            catch (ex){
                commit('SET_LOADING', false);
            }
            finally{
                commit('SET_LOADING', false);
            }
        },

        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        getShowMessage: (s) => s.showmessage,
        getAskOldPass: (s) => s.askoldpass,
        getOldPassword: (s) => s.password_old,
        getNewPassword: (s) => s.password_new,
        getNewRepeatPassword: (s) => s.password_repeat_new,
        getHelpTextArray: (s) => s.helptextarray
    }
}    

export default userReplacePassword;