import Vue from 'vue'

/*const Configurations = Object.freeze({
    C4: 8,
    C5: 32
});*/

/*function checkAccess(chapter, levelaccess) {
    switch (chapter.route)
    {   
            case "/security":
                return levelaccess==1;
            case "/ems":
                return levelaccess>0;
    }
}*/

const header =
{
    namespaced: true,
    state: {
        chapters: [
            /*{ 
                name: "Главная",
                route: "/",
            },*/
            {
                name:"Организации",
                route: "/enterprises"
            },
            {
                name:"Пользователи",
                route: "/users"
            },
            {
                name:"Справочники",
                route: "/referenses"
            },
            {
                name:"Безопасность",
                route: "/security"
            }
        ]
    },
    mutations: {
        
    },
    actions: {
        
    },
    getters: {
        getMyChapters: (state/*, getters, rootState, rootGetters*/) =>
        {
            //let userInfo = rootGetters['auth/getUserInfo'];
            let items = state.chapters.reduce( function (items, chapter) {
                //if (checkAccess(chapter, userInfo?.levelAccess))
                    items.push({ name: chapter.name, route: chapter.route });
                return items;
            }, []);
            if (Vue.config.devtools) {
                items.push({ name: "Песочница", route: "/sandbox" });
            }

            return items;
        }
    }
}

export default header;