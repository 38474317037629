import enterprises from './lists/enterprises';
import contragents from './lists/contragents';

export default {
    namespaced: true,
    state: () => ({
        collection : "Enterprises",
        statuses: [
            { collection: "Enterprises", value: null},
            { collection: "Contragents", value: "Enterprise"},
        ],
        searches: [
            { collection: "Enterprises", value: "" },
            { collection: "Contragents", value: "" },
        ]
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        PUSH_STATUS (state, payload) {
            let exists = state.statuses.find(x => x.collection == payload.collection);

            if (exists) {
                exists.value = payload.value;
            }
            else {
                state.statuses.push(payload);
            }
        },
        PUSH_SEARCH (state, payload) {
            let exists = state.searches.find(x => x.collection == state.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.searches.push(payload);
            }
        }
    },
    getters: {
        getCollection: (s) => s.collection,
        getStatus: (s) => s.statuses.find(x => x.collection === s.collection)?.value,
        getSearch: (s) => s.searches.find(x => x.collection === s.collection).value,
    },
    modules: {
        enterprises,
        contragents
    }
}