<template>
    <!--Шапка-->
    <div class="header">
        <div class="header-wrapper">
            <HeaderLogo />

            <!-- <div class="logo">
                <img src="@/assets/logo_short.svg">
                <div class="logo-title logo-title-reverse">
                    <div>Автоматизированное рабочее место</div>
                    <span>Портал городских услуг</span>
                </div>
            </div> -->
            
            <div class="top-menu">
                <v-tabs 
                background-color="transparent"
                mobile-breakpoint="0"
                slider-size="3"
                >
                    <v-tabs-slider color="#E43958"></v-tabs-slider>
            
                    <v-tab
                        v-for="chapter in myChapters"
                        :key="chapter.name"
                        :to="chapter.route"
                        active-class="top-menu-active-item">
                        {{ $t(chapter.name) }}
                    </v-tab>
                </v-tabs>
            </div>

            <div class="personal">
                <v-popover>
                    <i class="fas fa-user p-user-i" id="top-user-menu-btn"></i>
                    <template slot="popover">
                        <ul class="ribbon-button-submenu">
                            <li @click="userReplacePasswordWindow()">{{$t("Сменить_пароль")}}</li>
                        </ul>
                        <span class="ribbon-button-submenu-title">{{$t("Настройки")}}</span>
                    </template>
                </v-popover>
                <div class="el user-menu mail-down hidden-xs">
                    <div class="user-field">
                        <div>
                            <span class="user-name">
                                <span class="uname" v-tooltip.top-center="userInfo.name">{{ userInfo.name }}</span>
                                <span class="user-workplace">{{ userInfo.position }}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <a @click="fetchLogout">
                    <i class="fas fa-sign-out-alt p-other-i" v-tooltip.left-center="$t('Выход')"></i>
                </a>
            </div>
        </div>
        <UserReplacePassword ref="UserReplacePasswordRef" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UserReplacePassword from '@/components/dialogs/UserReplacePassword';
import HeaderLogo from '@/components/HeaderLogo';


export default {
    name: "Header",
    components:{
        UserReplacePassword,
        HeaderLogo,
    },
    computed: {
        ...mapGetters('header',
        {
            myChapters: 'getMyChapters'
        }),
        ...mapGetters('references', ['GetSecurityLevelAccess']),
        userInfo() {
            let userData = this.$store.getters['auth/getUserInfo'];
            let userName = userData.userName.replace(/ *\([^)]*\) */g, "");
            let userPosition = this.GetSecurityLevelAccess.find(item=>item.id==userData.levelAccess)?.Value;
            if (userData.warningMessagePassword)
                this.$notify.warning(this.$t('До_окончания_срока_действия_пароля_осталось_value_дней.message', {value: userData.passwordDaysLeft }));
            return { name: userName, position: userPosition };
        }
    },
    methods:{
        ...mapActions('auth', ['fetchLogout']),
        async userReplacePasswordWindow() {
            //await this.$refs.UserReplacePasswordRef.open();
            try {
                let rez = await this.$store.dispatch('dialogs/userReplacePassword/open', {showmessage:false})
                if (rez)
                    this.$notify.success(rez);
            }
            catch(ex)
            {
                console.log(ex);
            }
        }
    }
};
</script>