import sys from '@/services/system';

const selectEnterprise = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,
        multy: false,

        resolve: null,
        reject: null,

        enterprises: [],
        selected:[],

        search: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_MULTY(state, payload) {
            state.multy = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_SEARCH(state, payload) {
            state.search = payload;
            state.enterprise = null;
        },
        SET_ENTERPRISES(state, payload) {
            state.enterprises = payload;
        },
        SET_SELECTED(state, payload) {
            state.selected = payload;
        },
    },
    actions: {
        async open({ commit, dispatch }, {multyselect, selected}) {
            commit('SET_LOADING', true);

            commit('SET_MULTY', multyselect);
            
            commit('SET_SEARCH', null);
            commit('SET_VISIBLE', true);

            let enterprises = await dispatch('references/getEnterprises', { forDepartment: false }, { root: true });
            
            commit('SET_ENTERPRISES', enterprises);
            let selecteditems = [];
            if (selected && Array.isArray(selected)){
                selected.forEach(function(item){
                    let ent = enterprises.find(x=>x.id==item);
                    if (ent)
                        selecteditems.push({id: ent.id, name: ent.name});
            
               });
            }
            commit('SET_SELECTED', selecteditems);

            commit('SET_LOADING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            if (state.multy)
                state.resolve(state.selected);
            else 
                state.resolve(state.selected[0]);
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        isMulty: (s) => s.multy,
        isValid: (s) => (s.selected.length > 0),
        getEnterprise: (s) => s.enterprise,
        getSearch: (s) => s.search,
        getSelected: (s) => s.selected,
        getEnterprises(state) {

            if (state.search)
                return state.enterprises?.filter(x => sys.checkSearch(x.name, state.search)) ?? [];

            return state.enterprises;
        }
    }
}

export default selectEnterprise;