const edsSettings = {
    namespaced: true,
    state: {
        visible: false,
        resolve: null,
        reject: null,

        container: null,
        dn: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_CONTAINER(state, payload) {
            state.container = payload;
        },
        SET_DN(state, payload) {
            state.dn = payload;
        }
    },
    actions: {
        async open({ commit }, { source }) {
            commit('SET_VISIBLE', true);

            commit('SET_CONTAINER', source ? source.container : null);
            commit('SET_DN', source ? source.dn : null);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve(JSON.stringify({ container: state.container, dn: state.dn }));
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject();
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        getContainer: (s) => s.container,
        getDN: (s) => s.dn,
    }
}

export default edsSettings;