<template>
    <v-app>
        <Overlay class="top-level" absolute />
    
        <component :is="layout">
            <router-view />
        </component>

    </v-app>
</template>

<script>
import i18n from '@/i18n';
import LoginLayout from '@/layouts/LoginLayout.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import Overlay from './components/Overlay';

export default {
    name: 'App',
    components: {
        LoginLayout,
        MainLayout,
        Overlay,
    },
    data: () => ({
        customStyle: null
    }),
    computed: {
        layout() {
            return this.$route.meta.layout || 'login-layout';
        }
    },
    methods: {
        getLanguage() {
            switch (i18n.locale)
            {
                case "ru":
                    return 1;

                case "kk":
                    return 0;

                default:
                    return 1;
            }
        },
        callLangStyles() {

            if(this.customStyle == null){
                this.customStyle = document.createElement('style');
                document.head.appendChild(this.customStyle);
            }
            
            for (let index = 0; index < this.customStyle.sheet.cssRules.length; index++) {
                
                this.customStyle.sheet.deleteRule(index);
            }
            
            if (this.getLanguage() == 0){
                
                this.customStyle.sheet.insertRule(
                    `.v-application .cust-switch.v-input--is-label-active.v-input--is-dirty .v-input--switch__track:after { content: "Иә" }`, 0
                );
                this.customStyle.sheet.insertRule(
                    `.v-application .cust-switch .v-input--switch__track:after { content: "Жоқ" }`, 0
                );
                this.customStyle.sheet.insertRule(
                    `.v-application .error--text:not(.v-messages)::after, .chipAreaNoData::after { content: "Некорректное значение" }`, 0
                );
            }    
            else
            {
                
                this.customStyle.sheet.insertRule(
                    `.v-application .cust-switch.v-input--is-label-active.v-input--is-dirty .v-input--switch__track:after { content: "Да" }`, 0
                );
                this.customStyle.sheet.insertRule(
                    `.v-application .cust-switch .v-input--switch__track:after { content: "Нет" }`, 0
                );
                this.customStyle.sheet.insertRule(
                    `.v-application .error--text:not(.v-messages)::after, .chipAreaNoData::after { content: "Некорректное значение" }`, 0
                );
            }    
                        
        }
    },
    updated() {
        this.callLangStyles();
    }, 
};
</script>

<style lang="scss">
    @import "./assets/styles/common.css";
    @import "./assets/styles/notifystyle.css";

    .top-level {
        z-index: 1000 !important;
    }
</style>