/**
 * Модуль toolbar
 */
const toolbar = {
    namespaced: true,
    state: {
        // menu: [],
        isLock: false,
        lockedActions: []
        // isList: false
    },
    mutations: {
        // SET_MENU(state, payload) { 
        //     console.log('SET_MENU', payload)           
        //     state.menu = payload.menu;
        // },
        SET_ISLOCK(state, payload) {     
            state.isLock = payload.isLock;
        },
        // SET_ISLIST(state, payload) {            
        //     state.isList = payload.isList;
        // },
        SET_BTNLOCK(state, payload){
            let idx = state.lockedActions.indexOf(payload.lockedAction);
            if (idx <= -1)
                state.lockedActions.push(payload.lockedAction);
        },
        SET_BTNUNLOCK(state, payload){
            let idx = state.lockedActions.indexOf(payload.lockedAction);
            if (idx > -1) {
                state.lockedActions.splice(idx,1);
            }
        },
    },
    actions: {
        // setMenu({ commit }, { menu }) {
        //     console.log('setMenu', menu);
        //     commit({ type: 'SET_MENU', menu });
        // },
        setToolbarIsLock({ commit }, isLock) {
            commit({ type: 'SET_ISLOCK', isLock });
        },
        // setIsList({ commit }, { isList }) {
        //     commit({ type: 'SET_ISLIST', isList });
        // },
        setButtonLock({ commit }, lockedAction) {
            commit({ type: 'SET_BTNLOCK', lockedAction });
        },
        setButtonUnLock({ commit }, lockedAction) {
            commit({ type: 'SET_BTNUNLOCK', lockedAction });
        },
    },
    getters: {
        // menu: (s) => s.menu,
        isToolbarLock: (s) => s.isLock,
        // isList: (s) => s.isList,
        lockedActions: (s) => s.lockedActions
    },
};

export default toolbar;
