import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import ConnectionsHub from './plugins/connections-hub'
import Helpers from './plugins/helper-functions'
import moment from 'moment';
import i18n from '@/i18n'
import { EventBus } from '@/event-bus';
import notifier from '@/services/notifier';
import VueApexCharts from 'vue-apexcharts';
import _ from "lodash";
import VueTheMask from 'vue-the-mask'
import AsyncComputed from 'vue-async-computed';

document.title = process.env.VUE_APP_TITLE || 'АДМИНИСТРИРОВАНИЕ «ИНТЕГРО»';

Vue.prototype.$moment = moment;
Vue.prototype.$eventBus = EventBus;
Vue.prototype.$notify = notifier;
Vue.prototype.$_ = _;
/*число видимых кнопок в пагинации на списках разделов*/
Vue.prototype.$listPaginationTotal = 10;

Vue.config.productionTip = false;

Vue.directive('router-back', {
    bind(el) {
        el.addEventListener('click', (e) => {
            e.preventDefault()
            router.go(-1)
        })
    }
});

Vue.use(AsyncComputed, {
    errorHandler (stack) {
        console.log('AsyncComputed error!')
        console.log('---')
        console.log(stack)
    }
})

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.component(`v-employee-chip`, () => import('@/components/contractor-chips/v-employee-chip.vue'));
Vue.component(`v-enterprise-chip`, () => import('@/components/contractor-chips/v-enterprise-chip.vue'));
Vue.component(`v-workplace-chip`, () => import('@/components/contractor-chips/v-workplace-chip.vue'));
Vue.component(`v-doc-chip`, () => import('@/components/contractor-chips/v-doc-chip.vue'));
Vue.component(`v-contact-item`, () => import('@/components/ems/v-contact-item.vue'));
Vue.component(`v-file-item`, () => import('@/components/files/v-file-item.vue'));

Vue.component(`v-field`, () => import('@/components/card/v-field.vue'));

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
    created() {
        Vue.use(ConnectionsHub, this.$store);
        Vue.use(Helpers);
        Vue.use(VueTheMask);
    }
}).$mount('#app')