import users from './lists/configs';
import supportemployees from './lists/logs';

export default {
    namespaced: true,
    state: () => ({
        collection : "Logs",
        searches: [
            { collection: "Logs", value: "" },
            { collection: "Configs", value: "" },
        ]
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        PUSH_SEARCH (state, payload) {
            let exists = state.searches.find(x => x.collection == state.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.searches.push(payload);
            }
        }
    },
    getters: {
        getCollection (state) {
            return state.collection;
        },
        getSearch (state) {
            return state.searches.find(x => x.collection === state.collection).value;
        }
    },
    modules: {
        supportemployees,
        users
    }
}