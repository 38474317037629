import notifier from 'awesome-notifications';

let globalOptions = {
    labels: {
        tip: 'Подсказка',
        info: 'Информация',
        success: 'Успешно',
        warning: 'Внимание',
        alert: 'Ошибка',
        async: 'Загрузка',
        confirm: 'Требуется подтверждение',
        confirmOk: 'Ок',
        confirmCancel: 'Отмена',
    },
    messages: {
        success: 'Действие успешно выполнено',
        warning: 'Действие выполнено частично',
        alert: 'При выполнении действия произошла ошибка'
    },
    icons: {
        enabled: false
    }
};

export default new notifier(globalOptions);
