import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        meta: { layout: 'login-layout' },
        component: () => import('@/views/LoginFull.vue'),
    },
    {
        path: '/',
        name: 'Home',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/enterprises',
        name: 'Enterprises',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/Enterprises/Proxy.vue'),
    },
    {
        path: '/users',
        name: 'Users',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/Users/Proxy.vue'),
    },
    {
        path: '/referenses',
        name: 'Referenses',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/Referenses/Proxy.vue'),
    },
    {
        path: '/security',
        name: 'Security',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/Security/Proxy.vue'),
    },
    {
        path: '/about',
        name: 'About',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/About.vue')
    },
    {
        path: '/sandbox',
        name: 'Sandbox',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/Sandbox.vue'),
        beforeEnter: (to, from, next) =>  {
            if (Vue.config.devtools) {
                next();
            }
            else {
                this._vm.$notify.warning("Этот модуль недоступен в рабочем окружении!");
            }
        }
    },
    {
        path: '/ems/enterprise/card',
        name: 'EMSEnterpriseCard',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/EMS/Cards/Enterprise/EnterpriseCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/ems/employee/card',
        name: 'EMSEmployeeCard',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/EMS/Cards/Employee/EmployeeCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/ems/role/card',
        name: 'EMSRoleCard',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/EMS/Cards/Role/RoleCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/ems/reportquerytemplate/card',
        name: 'ReportQueryTemplateCard',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/EMS/Cards/ReportQueryTemplate/ReportQueryTemplateCard.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/counter',
        name: 'Counter',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/Settings/Counter/Counter.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/euolcounter',
        name: 'EuolCounter',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/Settings/Counter/EuolCounter.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/nomenclature',
        name: 'Nomenclature',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/Settings/Nomenclature/Nomenclature.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/enterprise',
        name: 'ContractorEnterprise',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/Settings/Contractor/Enterprise.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/person',
        name: 'ContractorPerson',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/Settings/Contractor/Person.vue'),
        props: (route) => ({ id: route.query.id, ...route.params })
    },
    {
        path: '/serverconfig',
        name: 'ConfigureServerCard',
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import('@/views/Security/cards/ConfigureServer.vue'),
    },
    {
        path: "*",
        meta: { layout: 'main-layout' , authRequired: true },
        component: () => import("@/views/Common/NotFound.vue")
    }
]

const router = new VueRouter({
    routes,
});

router.beforeEach(async (to, from, next) => {

    if (from === VueRouter.START_LOCATION)
    {
        await store.dispatch('auth/init', to);
    }
    
    if (!store.state.auth.authorized)
    {
        if (to.matched.some((record) => record.meta.authRequired))
        {
            next('/login');
        }
        else
            next();
    }
    else if (to.name === 'Login')
    {
        next('/');
    }
    else
    {
        next();
    }

});

export default router;
