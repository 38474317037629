import { httpAPI } from "@/api/httpAPI";

export default {
    namespaced: true,
    state: () => ({
        references: [],

        newDialogInnerExecuters:[],
        newDialogInnerExecutersSyncAt:0,

        newDialogSigners:[],
        newDialogSignersSyncAt:0,

        newDialogOuterExecuters:[],
        newDialogOuterExecutersSyncAt:0,

        newDialogESEDOExecuters:[],
        newDialogESEDOExecutersSyncAt:0,

        newDialogJurExecuters:[],
        newDialogJurExecutersSyncAt:0,

        newDialogFizExecuters:[],
        newDialogFizExecutersSyncAt:0,

        newDialogGroupExecuters:[],
        newDialogGroupExecutersSyncAt:0,

        enterprises:[],
        enterprisesSyncAt:0,

        сacheTimeInMinutes: 5,
        SecurityLevelAccess: 
        [   
            { id: 0, Value: "Пользователь СЭД" },     
            { id: 10, Value: "Специалист техподдержки" },
            { id: 50, Value: "Специалист ИБ" },
            { id: 100, Value: "Системный администратор" }
        ],
        licenseEnterprise: null,
        //Avrora.Objects.Modules.Docflow.Enumerators.DocumentTypes
        DocumentTypes: {
            1:  "ВсеU",
            2:  "Проект",
            3:  "Входящий_документ",
            4:  "Исходящий_документ",
            5:  "Внутренний_документ",
            6:  "Акт",
            7:  "Договор",
            8:  "Организационно_распорядительный_документ",
            9:  "Обращение_граждан",
            10: "Контрольный_документ",
            11: "Заключение",
            12: "Обращение",
            13: "Ответ_на_обращение",
            14: "Заявка",
            17: "НПА",
            18: "Протокол",
            19: "Запрос_отчета",
            20: "Общий_внутренний_документ"
        },
        DeclarantCategoryTypes: 
        [        
            { id: 1, Value: "Физическое_лицо" },
            { id: 2, Value: "Юридическое_лицо" }
        ],
        
        avaibleStatuses:
        {
            connected: {
                text: "Онлайн",
                style: {
                    'background-color': "#43B581"
                }
            },
            locked: {
                text: "Заблокирован",
                style: {
                    'background-color': "#F04747"
                }
            },
            withoutlogin: {
                text: "Без логина",
                style: {
                    'background-color': "#fff",
                    'color': "#444",
                    'border': "1px solid #444"
                }
            },
        },
    }),
    mutations:
    {
        PUSH_REFERENCE (state, payload)
        {
            state.references.push(payload);
        },
        UPDATE_REFERENCE (state, payload)
        {
            state.references[payload.index] = payload.value;
        },
        SET_NEW_DIALOG_INNEREXECUTERS (state, payload){
            state.newDialogInnerExecuters=payload
        },
        SET_NEW_DIALOG_INNEREXECUTERS_SYNC (state, payload){
            state.newDialogInnerExecutersSyncAt=payload
        },
        SET_NEW_DIALOG_SIGNERS (state, payload){
            state.newDialogSigners=payload
        },
        SET_NEW_DIALOG_SIGNERS_SYNC (state, payload){
            state.newDialogSignersSyncAt=payload
        },
        SET_NEW_DIALOG_OUTEREXECUTERS (state, payload){
            state.newDialogOuterExecuters=payload
        },
        SET_NEW_DIALOG_OUTEREXECUTERS_SYNC (state, payload){
            state.newDialogOuterExecutersSyncAt=payload
        },
        SET_NEW_DIALOG_ESEDOEXECUTERS (state, payload){
            state.newDialogESEDOExecuters=payload
        },
        SET_NEW_DIALOG_ESEDOEXECUTERS_SYNC (state, payload){
            state.newDialogESEDOExecutersSyncAt=payload
        },
        SET_NEW_DIALOG_JUREXECUTERS (state, payload){
            state.newDialogJurExecuters=payload
        },
        SET_NEW_DIALOG_JUREXECUTERS_SYNC (state, payload){
            state.newDialogJurExecutersSyncAt=payload
        },
        SET_NEW_DIALOG_FIZEXECUTERS (state, payload){
            state.newDialogFizExecuters=payload
        },
        SET_NEW_DIALOG_FIZEXECUTERS_SYNC (state, payload){
            state.newDialogFizExecutersSyncAt=payload
        },
        SET_NEW_DIALOG_GROUPEXECUTERS (state, payload){
            state.newDialogGroupExecuters=payload
        },
        SET_NEW_DIALOG_GROUPEXECUTERS_SYNC (state, payload){
            state.newDialogGroupExecutersSyncAt=payload
        },
        SET_LICENSEENTERPRISE (state, payload){
            state.licenseEnterprise = payload;
        },
        SET_ENTERPRISES (state, payload){
            state.enterprises = payload;
        },
        SET_ENTERPRISES_SYNC (state, payload){
            state.enterprisesSyncAt=payload
        }
    },
    actions:
    {
        async getTimestamp() {
            let time = null;

            let response = await httpAPI({
                url: `api/references/timestamp`,
                method: 'GET'
            });

            if (response)
                time = response.data.payload;
            return time;
        },
        async getReference({ commit, state }, { id }) {
            if (id === null || id === undefined)
                throw { message: "Значение id не может быть неопределенным" };

            let localRef = state.references.find(x => x.id == id);

            var now = this._vm.$moment(Date.now());

            if (localRef == null)
            {
                let response = await httpAPI({
                    url: `api/references/reference?type=${id}`,
                    method: 'GET'
                });

                if (response) {
                    localRef = { id, value: response.data.payload, syncAt: Date.now() };
                    commit('PUSH_REFERENCE', localRef);
                }
            }
            else if (this._vm.$moment.duration(now.diff(this._vm.$moment(localRef.syncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let index = state.references.indexOf(localRef);

                let response = await httpAPI({
                    url: `api/references/reference?type=${id}`,
                    method: 'GET'
                });

                if (response) {
                    localRef = { id, value: response.data.payload, syncAt: Date.now() };
                    commit({ type: 'UPDATE_REFERENCE', index, value: localRef });
                }
            }

            return localRef.value;
        },
        async getNewDialogExecuters({commit, state}, { params }){
            let ret = [];
            let query_param = {};
            var now = this._vm.$moment(Date.now());

            if (params.signersonly) {

                let signersCached = true;

                if (params.ignore_cache || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogSignersSyncAt))).asMinutes() > state.сacheTimeInMinutes) {

                    let responce = await httpAPI({
                        url: `api/ems/executerslist?filter=${JSON.stringify({signersonly:true, entid:params.entid})}`,
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' },
                    });
                    
                    if (responce) {
                        commit('SET_NEW_DIALOG_SIGNERS', responce.data.payload.InnerExecuters);
                        commit('SET_NEW_DIALOG_SIGNERS_SYNC', Date.now());
                        signersCached = false;
                    }
                    else
                        signersCached = true;
                }

                ret.Signers = { cached: signersCached, values: state.newDialogSigners };
            }
            else {
                if (params.entid)
                    query_param.entid = params.entid;
                if (params.include_inner && (params.ignore_cache || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogInnerExecutersSyncAt))).asMinutes() > state.сacheTimeInMinutes))
                    query_param.include_inner = true;

                if (params.include_outer && (params.ignore_cache || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogOuterExecutersSyncAt))).asMinutes() > state.сacheTimeInMinutes))    
                    query_param.include_outer = true;

                if (params.include_esedo && (params.ignore_cache || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogESEDOExecutersSyncAt))).asMinutes() > state.сacheTimeInMinutes))
                    query_param.include_esedo = true;

                if (params.include_jur && (params.ignore_cache || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogJurExecutersSyncAt))).asMinutes() > state.сacheTimeInMinutes))
                    query_param.include_jur = true;

                if (params.include_fiz && (params.ignore_cache || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogFizExecutersSyncAt))).asMinutes() > state.сacheTimeInMinutes))   
                    query_param.include_fiz = true;

                if (params.include_groups && (params.ignore_cache || this._vm.$moment.duration(now.diff(this._vm.$moment(state.newDialogGroupExecutersSyncAt))).asMinutes() > state.сacheTimeInMinutes))    
                    query_param.include_groups = true;

                if (query_param.include_inner || query_param.include_outer || query_param.include_esedo || query_param.include_jur || query_param.include_fiz || query_param.include_groups) {
                    let responce = await httpAPI({
                        url: `api/ems/executerslist?filter=${JSON.stringify(query_param)}`,
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' },
                    });
                    
                    if (responce) {

                        if (responce.data.payload.InnerExecuters) {
                            commit('SET_NEW_DIALOG_INNEREXECUTERS', responce.data.payload.InnerExecuters);
                            commit('SET_NEW_DIALOG_INNEREXECUTERS_SYNC', Date.now());
                        }
                        else 
                            query_param.include_inner = false;

                        if (responce.data.payload.OuterExecuters) {
                            commit('SET_NEW_DIALOG_OUTEREXECUTERS', responce.data.payload.OuterExecuters);
                            commit('SET_NEW_DIALOG_OUTEREXECUTERS_SYNC', Date.now());
                        }
                        else 
                            query_param.include_outer = false;

                        if (responce.data.payload.OuterESEDO) {
                            commit('SET_NEW_DIALOG_ESEDOEXECUTERS', responce.data.payload.OuterESEDO);
                            commit('SET_NEW_DIALOG_ESEDOEXECUTERS_SYNC', Date.now());
                        }
                        else 
                            query_param.include_esedo = false;

                        if (responce.data.payload.OuterJur) {
                            commit('SET_NEW_DIALOG_JUREXECUTERS', responce.data.payload.OuterJur);
                            commit('SET_NEW_DIALOG_JUREXECUTERS_SYNC', Date.now());
                        }
                            query_param.include_jur = false;

                        if (responce.data.payload.OuterFiz) {
                            commit('SET_NEW_DIALOG_FIZEXECUTERS', responce.data.payload.OuterFiz);
                            commit('SET_NEW_DIALOG_FIZEXECUTERS_SYNC', Date.now());
                        }
                            query_param.include_fiz = false;
                        
                        if (responce.data.payload.ExecuterGroups) {
                            commit('SET_NEW_DIALOG_GROUPEXECUTERS', responce.data.payload.ExecuterGroups);
                            commit('SET_NEW_DIALOG_GROUPEXECUTERS_SYNC', Date.now());
                        }
                            query_param.include_groups = false;
                    }
                }

                if (params.include_inner)
                    ret.InnerExecuters = { cached: !query_param.include_inner, values: state.newDialogInnerExecuters};

                if (params.include_outer)
                    ret.OuterExecuters = { cached: !query_param.include_outer, values: state.newDialogOuterExecuters};

                if (params.include_esedo)
                    ret.OuterESEDO = { cached: !query_param.include_esedo, values: state.newDialogESEDOExecuters};

                if (params.include_jur)
                    ret.OuterJur = { cached: !query_param.include_jur, values: state.newDialogJurExecuters};

                if (params.include_fiz)
                    ret.OuterFiz = { cached: !query_param.include_fiz, values: state.newDialogFizExecuters};

                if (params.include_groups)
                    ret.ExecuterGroups = { cached: !query_param.include_groups, values: state.newDialogGroupExecuters};
            }

            return ret;
        },
        async getLicenseEnterprise({commit, state}){
            if (state.licenseEnterprise == null){
                let responce = await httpAPI({
                    url: `api/security/getlicenseenterprise`,
                    method: 'GET',
                });
                
                if (responce) 
                    commit('SET_LICENSEENTERPRISE', responce.data.payload);
            }
            return state.licenseEnterprise;
        },
        async getEnterprises({ commit, state }) {

            var now = this._vm.$moment(Date.now());

            if (this._vm.$moment.duration(now.diff(this._vm.$moment(state.enterprisesSyncAt))).asMinutes() > state.сacheTimeInMinutes)
            {
                let response = await httpAPI({
                    url: `api/ems/enterpriseslist`,
                    method: 'GET'
                });
                
                if (response) {
                    commit('SET_ENTERPRISES', response.data.payload.data);
                }
            }

            return state.enterprises;
        },
    },
    getters:
    {
        GetReferences: (s) => s.references,
        GetReference: (s) => (refId) => s.references.find(ref => ref.id === refId)?.value ?? [],
        GetSecurityLevelAccess: (s) => s.SecurityLevelAccess,
        GetDocumentTypes: (s) => s.DocumentTypes,
        GetAvaibleStatuses: (s) => s.avaibleStatuses,
        GetDeclarantCategoryTypes: (s) => s.DeclarantCategoryTypes,
    }
}