import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/modules/auth';
import enterprises from '@/store/modules/enterprises/proxy';
import users from '@/store/modules/users/proxy';
import emsrefs from '@/store/modules/emsrefs/proxy';
import security from '@/store/modules/security/proxy';
import emsDialogs from '@/store/modules/ems/dialogs';
import emsCards from '@/store/modules/ems/cards';
import toolbar from '@/store/modules/toolbar';
import header from './header';
import dialogs from './modules/dialogs';
import references from '@/store/references'

Vue.use(Vuex)

const store = new Vuex.Store({
    // strict: process.env.NODE_ENV !== 'production',
    state:
    {
        backendConnectionState: null,
        overlayVisibleValue: false,
        overlayVisible: false,
        overlayText: 'Загрузка...',
        progress: null,
        region: 0,
    },
    mutations:
    {
        SET_OVERLAY_VISIBLE(state, payload) {
            state.overlayVisible = payload.visible;
        },
        SET_OVERLAY_TEXT(state, payload) {
            state.overlayText = payload.overlayText;
        },
        SET_OVERLAY_PROGRESS(state, payload) {
            state.progress = payload.progress;
        },
        SET_BACKEND_CONNECTION_STATE(state, payload) {
            state.backendConnectionState = payload;
        },
        SET_REGION(state, payload) {
            state.region = payload;
        },
    },
    getters:
    {
        getProgress: (s) => s.progress,
        overlayVisible: (s) => s.overlayVisible,
        overlayText: (s) => s.overlayText,
        getBackendConnectionState: (s) => s.backendConnectionState,
        
        //#region validation rules 
        getRequiredRule: () => {
            return [
                v => (!['string', 'number'].includes(typeof v) && !!v) || (typeof v == 'number' && v >= 0) || (typeof v == 'string' && !!v.trim()) || 'Поле обязательно'
            ];
        },
        getMultipleRule: () => {
            return [
                v => v.length > 0 || "Поле обязательно"
            ];
        },
        getIntegerRule: () => {
            return [
                v => (/^\d+$/.test(v) || v?.length === 0 || !v) || 'Только числа',
                v => ( v >= 0 ) || 'Значение не может быть меньше нуля',
                v => ( v <= 2147483647 ) || 'Слишком большое значение для типа int'
            ];
        },
        getDefaultGuidRule: () => {
            return [
                v => (v !== "00000000-0000-0000-0000-000000000000" ) || 'Поле обязательно'
            ];
        },
        getIntegerNotNullRule: () => {
            return [
                v => !!v || 'Поле обязательно',
                v => ( v > 0 ) || 'Значение не может быть меньше или равно нулю',
                v => ( v <= 2147483647 ) || 'Слишком большое значение для типа int'
            ];
        },
        getPhoneRule: () => {
            return [
                v => /^\+?(-*\d){6,11}-*$/.test(v) || 'Некорректный номер',
            ];
        },
        getIinRule: () => {
            return [
                v => (/^\d+$/.test(v) || v?.length === 0 || !v) || 'Только числа',
                v => (String(v)?.length == 12 || String(v)?.length === 0 || !v) || '12 символов'
            ];
        },
        //#endregion
        getUserRegion: (s) => (s.region < 10) ? '0' + s.region.toString() : s.region.toString(),
    },
    actions: {
        async setOverlayVisible({ commit }, { visible, text }) 
        {          
            if (this.overlayVisible == visible)
                return

            this.overlayVisibleValue = visible;

            if (visible)
            {
                await (new Promise(resolve => setTimeout(resolve, 200)));
                if (this.overlayVisibleValue != visible)
                    return;
            }
            else
            {
                commit({ type: 'SET_OVERLAY_PROGRESS', progress: null});  
            }

            commit({ type: 'SET_OVERLAY_VISIBLE', visible: this.overlayVisibleValue });
                
            if (text)
                commit({ type: 'SET_OVERLAY_TEXT', overlayText: text });
            else
                commit({ type: 'SET_OVERLAY_TEXT', overlayText: 'Загрузка...' });
        },
        updateProgress({ commit }, { progress }){
            console.log('progress', progress);
            commit({ type: 'SET_OVERLAY_PROGRESS', progress });
        }
    },
    modules: {
        auth,
        enterprises,
        users,
        emsrefs,
        security,
        emsDialogs,
		references,
        emsCards,
        toolbar,
        header,
        dialogs
    }
});

export default store;

//делаем слепок "чистого" состояния без реактивностей
const initialState = JSON.stringify(store.state);

//метод для сброса состояние хранилища https://vuex.vuejs.org/api/#replacestate
export function resetStoreState() {
    store.replaceState(JSON.parse(initialState));
}