import addSubDivision from "./add-sub-division";
import addWorkplace from "./add-workplace";
import addJobTitle from "./add-job-title";
import edsSettings from "./eds-settings";

const emsDialogs = {
    namespaced: true,
    modules: {
        addSubDivision,
        addWorkplace,
        addJobTitle,
        edsSettings
    }
}

export default emsDialogs;