import moment from 'moment';
import i18n from '@/i18n'

const helpers = {
    getOnlySelectedParams: function(extendedTableFilter) {
        return Object.fromEntries(Object.entries(JSON.parse(JSON.stringify(extendedTableFilter)))
        .filter(i => i[1].value != null && (!Array.isArray(i[1].value) || (Array.isArray(i[1].value) && i[1].value.length > 0)))
        .map((i) => {
            if (typeof i[1].value === 'string')
                return [ [i[0]], i[1].value ];
            else if (typeof i[1].value === 'object')
            {
                if (Array.isArray(i[1].value))
                    return [ [i[0]], i[1].value.map(x => x.id ?? x)];
                else
                    return  [ [i[0]], i[1].value.id ?? i[1].value ];
            }
        }));
    },
    isExtendedFilterPanelVisible (source) {
        //итерируем объект по свойствам
        for (var property in source)
        {
            /**Значение свойства объекта фильтра
            *  value может быть примитивом / объектом / массивом. */
            var propValue = source[property].value;

            //если значение свойства фильтра определено
            if (propValue !== undefined && propValue !== null)
            {
                //если значение является объектов
                if (typeof propValue === 'object')
                {
                    //если значение это не пустой массив
                    if (Array.isArray(propValue))
                    {
                        if (propValue.length > 0)
                        {
                            return true;
                        }
                    }
                    else
                    {
                        //фильтр не пуст
                        return true;
                    }
                }
                else
                {
                    return true;
                }
            }
        }
        return false;
    },
    getFormattedDate(date, format){
        var dateMoment = moment(date);

        if (dateMoment != null && dateMoment.isValid()) {
            return dateMoment.format(format);            
        }

        return null;
    },
    getEmployeeStatus: function(employee) {
        if (!employee.login)
            return "withoutlogin";
        else if(employee.connected)    
            return "connected";
        else if (employee.locked)
            return "locked";
        else 
            return "";    
    },
    getEmployeeActions: function(employee){
        let actions = []
        if(employee.connected)    
            actions.push({Action:"EmployeeDropToken", Text: i18n.t("Сбросить_сессию"), Icon: "fas fa-user-slash"});
        return actions;
    },
}

export default {
    install (Vue) {
        Vue.prototype.$helpers = helpers;
    }
};