const addJobTitle = {
    namespaced: true,
    state: {
        visible: false,
        resolve: null,
        reject: null,
        new: false,
        //code: null,
        name: null,
        shortname: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_NEW(state, payload) {
            state.new = payload;
        },
        /*SET_CODE(state, payload) {
            state.code = payload;
        },*/
        SET_NAME(state, payload) {
            state.name = payload;
        },
        SET_SHORTNAME(state, payload) {
            state.shortname = payload;
        }
    },
    actions: {
        async open({ commit }, { isNew }) {
            commit('SET_NEW', isNew);
            commit('SET_VISIBLE', true);

            if (isNew) {
                //commit('SET_CODE', null);
                commit('SET_NAME', null);
                commit('SET_SHORTNAME', null);
            }

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ /*code: state.code,*/ name: state.name, shortname: state.shortname });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject();
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isNew: (s) => s.new,
        //getCode: (s) => s.code,
        getName: (s) => s.name,
        getShortname: (s) => s.shortname,
    }
}

export default addJobTitle;