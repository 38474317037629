import allusers from './lists/allusers';
import connectedusers from './lists/connectedusers';
import lockedusers from './lists/lockedusers';
import withoutloginusers from './lists/withoutloginusers';
import bylevelusers from './lists/bylevelusers';

export default {
    namespaced: true,
    state: () => ({
        collection : "AllUsers",
        statuses: [
            { collection: "AllUsers", value: null },
            { collection: "ConnectedUsers", value: null },
            { collection: "LockedUsers", value: null },
            { collection: "WithoutLoginUsers", value: null },
            { collection: "ByLevelUsers", value: "InnerUsers" },
        ],
        searches: [
            { collection: "AllUsers", value: "" },
            { collection: "ConnectedUsers", value: "" },
            { collection: "LockedUsers", value: "" },
            { collection: "WithoutLoginUsers", value: "" },
            { collection: "ByLevelUsers", value: "" },
        ]
    }),
    mutations: {
        SET_COLLECTION (state, payload) {
            state.collection = payload;
        },
        PUSH_STATUS (state, payload) {
            let exists = state.statuses.find(x => x.collection == payload.collection);

            if (exists) {
                exists.value = payload.value;
            }
            else {
                state.statuses.push(payload);
            }
        },
        PUSH_SEARCH (state, payload) {
            let exists = state.searches.find(x => x.collection == state.collection);

            if (exists)
            {
                exists.value = payload.value;
            }
            else
            {
                state.searches.push(payload);
            }
        }
    },
    getters: {
        getCollection: (s) => s.collection,
        getStatus: (s) => s.statuses.find(x => x.collection === s.collection)?.value,
        getSearch: (s) => s.searches.find(x => x.collection === s.collection)?.value,
    },
    modules: {
        allusers,
        connectedusers,
        lockedusers,
        withoutloginusers,
        bylevelusers
    }
}