import { httpAPI } from "@/api/httpAPI";

function PrepareTree(divisions) {
    let result = [];
    result.push({ id: divisions[0]?.id, label: divisions[0]?.name });
    let children = Array.from(divisions[0]?.children ? divisions[0].children.filter(x => x.type === 'Department') : []);

    if (children.length) {
        let branches = children.reduce( function (items, division) {
            if (division.type === 'Department') {
                items.push(handleBranch(division));
            }
            return items;
        }, []);
        result[0].children = branches;
    }

    return result;
}

function handleBranch(source) {
    let branch = { id: source.id, label: source.name };
    let children = Array.from(source.children ?  source.children.filter(x => x.type === 'Department') : []);
    
    if (children.length) {
        branch.children = [];        
        children.forEach(item => {
            branch.children.push(handleBranch(item));
        });
    }

    return branch;
}

const addWorkplace = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,
        resolve: null,
        reject: null,
        new: false,
        divisions: [],
        parentDivision: null,
        position: null,
        positions: [],
        index: ''
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_NEW(state, payload) {
            state.new = payload;
        },
        SET_DIVISIONS(state, payload) {
            state.divisions = payload;
        },
        SET_PARENT_DIVISION(state, payload) {
            state.parentDivision = payload;
        },
        SET_POSITIONS(state, payload) {
            state.positions = payload;
        },
        SET_POSITION(state, payload) {
            state.position = payload;
        },
        SET_INDEX(state, payload){
            state.index = payload;
        }
    },
    actions: {
        async open({ commit, state }, { selected, divisions, isNew }) {
            commit('SET_DIVISIONS', PrepareTree(divisions));
            commit('SET_NEW', isNew);
            commit('SET_LOADING', true);
            commit('SET_VISIBLE', true);

            let filter = JSON.stringify({ SearchStr: "", OrderBy: 3, OrderDir: false });
            let response = await httpAPI({
                url: `api/ems/jobtitleslist?filter=${filter}`,
                method: 'GET'
            });

            commit('SET_POSITIONS', response?.data?.payload?.data ?? []);

            if (isNew) {
                commit('SET_PARENT_DIVISION', selected);               
                commit('SET_POSITION', null);
                commit('SET_INDEX', '');
            }
            else {
                commit('SET_PARENT_DIVISION', selected.parent_id);
                
                let workplaceRequest = await httpAPI({
                    url: `api/ems/getworkplace?id=${selected.id}`,
                    method: 'GET'
                });
                commit('SET_POSITION', state.positions.find(x => x.id === workplaceRequest?.data?.payload?.jobtitleid) ?? null);
                commit('SET_INDEX', workplaceRequest?.data?.payload?.index ?? '');
            }

            commit('SET_LOADING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve({ position: state.position, parent: state.parentDivision, index: state.index });
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject();
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        isNew: (s) => s.new,
        getParentDivision: (s) => s.parentDivision,
        getDivisions: (s) => s.divisions,
        getPosition: (s) => s.position,
        getPositions: (s) => s.positions,
        getIndex: (s) => s.index
    }
}

export default addWorkplace;