import { httpAPI } from "@/api/httpAPI";

const executersGroup = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,

        entid: null,
        executerGroup: null,

        resolve: null,
        reject: null,
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_EXECUTER_GROUP(state, payload) {
            state.executerGroup = payload;
        },
        SET_ENTID(state, payload) {
            state.entid = payload;
        },
        UPDATE_EXECUTER_GROUP(state, payload)
        {
            this._vm.$_.set(state.executerGroup, payload.property, payload.value)
        }
    },
    actions: {
        updateDataSource({ commit }, data) {
            commit('UPDATE_EXECUTER_GROUP', data);
        },
        async open({ state, commit, dispatch }, { groupId, enterpriseId }) {
            commit('SET_LOADING', true);
            commit('SET_VISIBLE', true);
            commit('SET_ENTID', enterpriseId);
            let response = await httpAPI({
                url: groupId != null
                    ? `api/edit?type=Documents.ExecuterGroup&id=${groupId}&entid=${enterpriseId}`
                    : `api/new?type=Documents.ExecuterGroup&entid=${enterpriseId}`,
                method: 'GET'
            });
            
            commit('SET_LOADING', false);

            if (response) {
                commit('SET_EXECUTER_GROUP', response.data.payload.Data.Object);

                let dataList = state.executerGroup.Executers.reduce( function (list, executer) {

                    if (executer.ExecuterID !== '00000000-0000-0000-0000-000000000000')
                        list.push([ executer.ExecuterID, `${executer.ExecuterName}`, "сотрудники", true, executer.EnterpriseID ]);
                    else
                        list.push([ executer.EnterpriseID, `${executer.ExecuterName}`, "организации", true, executer.EnterpriseID ]);

                    return list;
                }, []);

                dispatch('updateDataSource', { property: 'WebExecuterDataList', value: { __type: "DataList:#Avrora.Objects.Web", Data: dataList } });
            }
            else {
                commit('SET_VISIBLE', false);
                commit('SET_EXECUTER_GROUP', null);
                return Promise.reject({ message: "Ошибка загрузки группы исполнителей" });
            }

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.resolve(state.executerGroup);
        },

        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        },
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        getExecuterGroup: (s) => s.executerGroup,
        getEnterpriseId: (s) => s.entid
    }
}

export default executersGroup;