import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

export default {
    install(Vue, store) 
    {
        
        let url = process.env.NODE_ENV === "production" 
            ? '/connections-hub'
            : 'http://localhost:7000/connections-hub';
        
        const connection = new HubConnectionBuilder()
            .withUrl(url)
            .configureLogging(LogLevel.Information)
            .build();

        const connectionsHub = new Vue();

        connectionsHub.GetConnectionId = () => {
            return connection.connectionId;
        }

        //#region События, которые инициирует клиент
        connectionsHub.CheckToken = (token) => {
            return connection.invoke('CheckToken', token)
        }

        connectionsHub.DiscoverRegion = () => {
            return connection.invoke('DiscoverUserRegion');
        }
        //#endregion

        //#region События, на которые подписан клиент

        /*connection.on('Foo', (message) => {
            connectionsHub.$emit('foo', { message })
        });*/
        connection.on('InactiveDetected', (message) => {
            connectionsHub.$emit('on-inactive-detected', { invokedAt: message })
        });
        //#endregion
        
        Vue.prototype.$connectionsHub = connectionsHub;

        //#region Запуск SignalR

        let startedPromise = null;

        function start() {
            if (startedPromise != null && store.getters.getBackendConnectionState != connection.state) {
                store.commit("SET_BACKEND_CONNECTION_STATE", connection.state);                
            }
            
            startedPromise = connection
                .start()
                .then(() =>
                {
                    store.commit("SET_BACKEND_CONNECTION_STATE", connection.state);

                    connection.invoke('DiscoverUserRegion')
                        .then(data => store.commit("SET_REGION", data));
                })
                .catch(err =>
                {
                    console.error('Failed to connect with hub', err)
                    return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
                });

            return startedPromise;
        }

        connection.onclose(() => start());

        start();

        //#endregion
    }
}