import Vue from 'vue'
import VueI18n from 'vue-i18n'
import kk from '@/locales/kk.json'
import ru from '@/locales/ru.json'

Vue.use(VueI18n)

let messages = {
    kk: kk,
    ru: ru,
}

//function loadLocaleMessages () {
//    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//    console.log(locales);
//  const messages = {}
//  locales.keys().forEach(key => {
//    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//    if (matched && matched.length > 1) {
//      const locale = matched[1]
//      messages[locale] = locales(key)
//    }
//  })
//  return messages
//}

export default new VueI18n({
    locale: localStorage.getItem('AppLocale') ?? 'kk',
    fallbackLocale: 'kk',
    messages: messages,
    silentTranslationWarn: true
});