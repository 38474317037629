const enterpriseCard = {
    namespaced: true,
    state: {
        active_tab: '0',
        employees:[],
        vacancys:[],
        dragStructElement: null,
        dragOverStructElement: null,
        openStructNodes:[]
    },
    mutations: {
        SET_ACTIVE_TAB(state, payload) {
            state.active_tab = payload;
        },
        SET_EMPLOYEES(state, payload) {
            state.employees = payload;
        },
        SET_VACANCYS(state, payload) {
            state.vacancys = payload;
        },
        SET_DRAG_STRUCT_ELEMENT(state, payload){
            state.dragStructElement = payload;
        },
        SET_DRAG_OVER_STRUCT_ELEMENT(state, payload){
            state.dragOverStructElement = payload;
        },
        SET_OPEN_STRUCT_NODE(state,payload){
            state.openStructNodes = payload;
        }, 
        PUSH_OPEN_STRUCT_NODE(state,payload){
            if (!state.openStructNodes)
                state.openStructNodes=[];
            if (state.openStructNodes.indexOf(payload) < 0)
                state.openStructNodes.push(payload);
        },
        REMOVE_OPEN_STRUCT_NODE(state,payload){
            if (state.openStructNodes && state.openStructNodes.length > 0)
                state.openStructNodes.splice(state.openStructNodes.indexOf(payload), 1);
        }
    },
    actions: {
        async clearDragElements({ commit }) {
            commit('SET_DRAG_STRUCT_ELEMENT', null);
            commit('SET_DRAG_OVER_STRUCT_ELEMENT', null);
        },
        async clearOpenStructNodes({ commit }) {
            commit('SET_OPEN_STRUCT_NODE',[]);
        }
    },
    getters: {
        getActiveTab: (s) => s.active_tab,
        getEmployees: (s) => s.employees,
        getVacancys: (s) => s.vacancys,
        getDragStructElement: (s) => s.dragStructElement,
        getDragOverStructElement: (s) => s.dragOverStructElement,
        getOpenStructNodes: (s) => s.openStructNodes
    }
}

export default enterpriseCard;