//import { httpAPI } from "@/api/httpAPI";
import i18n from '@/i18n'
import sys from '@/services/system';

const selectMembers = {
    namespaced: true,
    state: {
        visible: false,
        loading: false,

        resolve: null,
        reject: null,
        
        search: "",

        source: {},
        sources: {},
        selected: [],

        options: null
    },
    mutations: {
        SET_VISIBLE(state, payload) {
            state.visible = payload;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_RESOLVE(state, payload) {
            state.resolve = payload;
        },
        SET_REJECT(state, payload) {
            state.reject = payload;
        },
        SET_SEARCH(state, payload) {
            if (payload)
                state.search = payload;
            else 
                state.search = '';
        },
        SET_SOURCE(state, payload) {
            state.source = payload;
        },
        SET_SELECTED(state, payload) {
            state.selected = payload;
        },
        PUSH_SELECTED(state, payload) {
            state.selected.push(payload);
        },
        REMOVE_SELECTED(state, payload) {
            state.selected.splice(state.selected.indexOf(payload), 1);
        },
        SET_OPTIONS(state, payload) {
            state.options = payload;
        }
    },
    actions: {
        async open({ commit, state }, externalOptions) {
            commit('SET_SEARCH', "");
            commit('SET_SELECTED', []);

            commit('SET_OPTIONS', externalOptions);

            commit('SET_LOADING', true);
            commit('SET_VISIBLE', true);            
            
            commit('SET_SOURCE', externalOptions.source ?? {});
            //#region Поиск выбранных
            externalOptions.selected.forEach((selectedId) =>
            {
                if (state.selected.findIndex( x => x.workplaceId === selectedId) < 0)
                {
                    let item = state.source.find(x => x[0] === selectedId);

                    if (item)
                        commit('PUSH_SELECTED', { type: 1, workplaceId: item[0], employeeId: item[2], name: item[1], description: item[3]});
                }
            });
            //#endregion

            commit('SET_LOADING', false);

            return new Promise((resolve, reject) => {
                commit('SET_RESOLVE', resolve);
                commit('SET_REJECT', reject);
            });
        },
        async addSelected({ commit, state/*, dispatch*/ }, item) {
            if (state.options.multiple === false)
                commit('SET_SELECTED', []);
            commit('PUSH_SELECTED', { type: 1, workplaceId: item[0], employeeId: item[2], name: item[1], description: item[3] });
         },
        removeSelected({ commit }, item) {
            commit('REMOVE_SELECTED', item);
        },
        removeSelectedById({commit, state}, item_id){
            let idx = state.selected.findIndex( x => (x.type==0 && x.enterprise===item_id) || (x.type==1 && x.workplaceId===item_id))                
            if ( idx >= 0)
                commit('REMOVE_SELECTED', state.selected[idx]);
        },
        async ok({ commit, state }) {
            commit('SET_VISIBLE', false);

            if (state.options.multiple === true)
                state.resolve(state.selected.filter(executer => executer.banned !== true));
            else
                state.resolve(state.selected[0]);
        },
        async cancel({ commit, state }) {
            commit('SET_VISIBLE', false);
            state.reject({ message: "Cancelled" });
        }
    },
    getters: {
        isVisible: (s) => s.visible,
        isLoading: (s) => s.loading,
        getTitle: (s) => i18n.t(s.options?.title),
        getSearch: (s) => s.search,
        getSource: (s) => s.source,
        getSelected: (s) => s.selected,
        isOkDisabled: (s) => s.selected.filter(executer => executer.banned !== true).length === 0,
        allowed: (state) => {

            if (state.visible === false || state.loading === true)
                return;

            if (state.source?.length == 0)
                return [];

            return state.source
                .filter(member => !state.selected.map(x => x.workplaceId).includes(member[0]) && sys.checkSearch(member[1], state.search))
                .sort(function (a, b) {
                    return ('' + a[1]).localeCompare(b[1]);
                });
        }
    }
}

export default selectMembers;